import react, { useState, useEffect } from "react";
import styles from "./index.less";
import menuClose from "./image/gkx/menuClose.svg";
import menuOpen from "./image/gkx/menuOpen.svg";
import exitIcon from "./image/gkx/exitIcon.svg";
import { message } from "antd";
import user from "./image/gkx/user.png";
import cellGraph from "./image/gkx/cell-graph.png";
import cvoidGraph from "./image/gkx/cvoid-graph.png";
import AuthApi from "./auth/index";
import gkxLogo from "./image/gkx/gkxlogoBlack.svg";
import Login from "./Login";

const defaultUserInfo = {
  token: "",
  account: "",
  id: "",
  aminer_token: "",
  realName: "",
  cardNo: "",
  telephone: "",
  head_url: "",
  libraryStatus: 0,
  libraryMessage: "",
  role: 0,
  isFetchedUserInfo: false,
  asyncRoutestMark: false,
};
const graphList = [
  {
    title: "自然科学知识图谱",
    icon: cvoidGraph,
    key: "nature",
    url: "https://kg-nature.itic-sci.com/",
  },
  {
    key: "cell",
    title: "细胞与基因知识图谱",
    icon: cellGraph,
    url: "https://kg-cg.itic-sci.com/",
  },
];
function FloatMenu(props) {
  const [status, setStatus] = useState(false);
  const [loginVisiable, setLoginVisiable] = useState(false);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  useEffect(() => {
    AuthApi.userInfo({}).then((info) => {
      if (info?.code === 0) {
        setUserInfo(info.data);
      }
    });
  }, []);
  return (
    <div className={`${styles.floatMenu} `}>
      <div
        className={`${styles.circleBtn} ${status && styles.circleBtnActive}`}
        onClick={() => setStatus(!status)}
      >
        <img src={menuClose} alt="" className={`${styles.menuClose} `} />
        <img src={menuOpen} alt="" className={`${styles.menuOpen} `} />
      </div>
      <div
        className={`${styles.container} ${status && styles.containerActive}`}
      >
        {/* <div className={`${styles.topPart}`}>
          <img
            src={userInfo.head_url || user}
            alt=""
            className={`${styles.user} `}
          />
          <span
            className={`${styles.statusText}`}
            onClick={() => !userInfo.account && setLoginVisiable(true)}
          >
            {userInfo.account ? userInfo.account : "未登录"}
          </span>
          {userInfo.account && (
            <img
              src={exitIcon}
              alt=""
              onClick={async () => {
                let res = await AuthApi.logout();
                if (res.code === 0) {
                  setUserInfo(defaultUserInfo);
                  window.localStorage.removeItem("IS-USER-LOGIN");
                } else if (res?.message) {
                  message.error(res?.message);
                }
              }}
              className={`${styles.exitIcon} `}
              title="退出登录"
            />
          )}
        </div> */}

        <p className={`${styles.switchLine}`}>切换图谱</p>

        <div className={`${styles.graphContainer}`}>
          {graphList.map((item, key) => (
            <div
              key={key}
              className={`${styles.graph}  ${
                item.key === "nature" && styles.graphActive
              }
`}
            >
              <img src={item.icon} alt="" className={`${styles.icon} `} />
              <span
                className={`${styles.title}`}
                onClick={() => {
                  if (item.key !== "nature") {
                    window.open(item.url, "_self");
                  }
                }}
              >
                {item.title}
              </span>
            </div>
          ))}
        </div>

        <img
          src={gkxLogo}
          alt=""
          className={`${styles.gkxLogo}`}
          onClick={() => window.open("https://itic-sci.com/", "_self")}
        />
      </div>
      {loginVisiable && (
        <Login setLoginVisiable={setLoginVisiable} setUserInfo={setUserInfo} />
      )}
    </div>
  );
}

export default FloatMenu;
