import axios from "axios";

const service = axios.create({
  baseURL: "https://dev.sci-brain.cn/",
  timeout: 5 * 60 * 1000,
  headers: {
    "content-Type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      console.log(res.message);
    }
    return res;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);


export default service;
