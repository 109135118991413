import { post } from "../../../service";
export { encrypt } from "./crypto";

export const AUTH_URL = {
  login: "https://itic-sci.com/gkx_sso/login",
  userInfo: "https://itic-sci.com/gkx_sso/user_by_token",
  logout: "https://itic-sci.com/gkx_sso/logout",
};

export interface ILogin {
  register_name: string;
  password: string;
}
const login = async (data: ILogin) =>
  post({ url: AUTH_URL.login, data, withCredentials: true });
const logout = async () =>
  post({ url: AUTH_URL.logout, withCredentials: true });
const userInfo = async (data: {}) =>
  post({ url: AUTH_URL.userInfo, data, withCredentials: true });

export default {
  login,
  userInfo,
  logout,
};
