import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import * as THREE from "three";
import ForceGraph3D from "3d-force-graph";
import debounce from "lodash/debounce";
import SpriteText from "three-spritetext";
import { COLOR, cor_to_ball, ball_to_cor, calcTextPosition } from "../const";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { getScienceKnowledgeSubjects, getSubjectBylayer } from "../service";

const distance = 7500;
const globeTextureLoader = new THREE.TextureLoader();

let myGraph;
let nodes;
let prevNodes;
let nodeDisplayInterval3 = null;

const CAMERA_INIT_DISTANCE = 7500;

let currentAppear = "水产学";

let nowTarget = [
  CAMERA_INIT_DISTANCE,
  CAMERA_INIT_DISTANCE,
  CAMERA_INIT_DISTANCE,
];
let cameraStepNum = 80;
let curx = 0;
let cury = 0;
let curz = distance;

const getEarthBall = (scene) => {
  globeTextureLoader.load(
    "/dashboard/assets/images/earth_1.png",
    function (texture) {
      var globeGgeometry = new THREE.SphereGeometry(15000, 100, 100);
      let globeMaterial = new THREE.MeshBasicMaterial();
      globeMaterial.color = new THREE.Color(3257463);
      globeMaterial.fog = false;
      globeMaterial.transparent = true;
      globeMaterial.opacity = 0.16;
      globeMaterial.blending = THREE.AdditiveBlending;
      globeMaterial.depthWrite = false;

      globeMaterial.map = texture;
      globeMaterial.map.generateMipalphaMaps = false;
      globeMaterial.map.magFilter = THREE.LinearFilter;
      globeMaterial.map.minFilter = THREE.LinearFilter;
      globeMaterial.needsUpdate = true;

      var Mesh = new THREE.Mesh(globeGgeometry, globeMaterial);
      Mesh.visible = true;
      Mesh.matrixAutoUpdate = false;
      Mesh.updateMatrix();
      Mesh.matrixAutoUpdate = false;
      scene.add(Mesh);
    }
  );
};

const KgGraph = (props) => {
  const {
    curTabIndex,
    lang,
    ids,
    isStop,
    setCurrentClickNode,
    refs,
    update,
    forceUpdate,
    setCurTabIndex,
    handleNext,
    showAllNode,
    setReady,
    ready,
  } = props;
  const isZh = !!(lang === "zh");
  const allData = useRef({});
  const sujectData = useRef({});
  const timer = useRef(null);
  const timerLayer = useRef(null);
  const timerText = useRef(null);
  const loopNum = useRef(0);
  useImperativeHandle(refs, () => ({
    startAnimation,
    stopAnimation,
  }));

  function isActivity(name) {
    if (showAllNode.current) {
      return true;
    }
    return name === currentAppear;
  }

  function destory() {
    if (!myGraph) {
      return false;
    }
    const scene = myGraph.scene();
    const renderer = myGraph.renderer();
    scene.clear();
    renderer.dispose();
    renderer.forceContextLoss();
    renderer.content = null;
    // cancelAnimationFrame(animationID) // 去除animationFrame
    const gl = renderer.domElement.getContext("webgl");
    gl && gl.getExtension("WEBGL_lose_context").loseContext();
  }

  useEffect(() => {
    async function init() {
      // let data = await requestFn();
      // 初始化图设置
      myGraph = ForceGraph3D();
      myGraph(document.getElementById("aaabb"))
        .forceEngine("d3")
        // .graphData(data)
        // .nodeVal((node) => `${node.value}`)
        .nodeLabel((node) => `${node.name_zh}`)
        .nodeId("node_id")
        .nodeColor((node) => `${COLOR[node.group]}`)
        .nodeResolution(20)
        .cooldownTicks(0)
        .warmupTicks(100)
        .nodeVal((node) => (node.layer <= 0 ? node.value * 3 : node.value))
        .nodeAutoColorBy("group")
        .enableNodeDrag(false)
        .linkColor((node) => {
          return showAllNode.current
            ? "rgba(255,255,255,.2)"
            : isActivity(node.source_name_zh)
            ? "rgba(255,255,255,.2)"
            : "rgba(255,255,255,0.1)";
        })
        .linkWidth((node) => {
          return isActivity(node.source_name_zh) && !showAllNode.current
            ? 6
            : 1;
        })
        // .linkDirectionalParticleWidth(40)

        .onNodeHover((node) => {
          if (node) {
            if (isStop.current) {
              debounce(stopAnimation, 1000);
              // stopAnimation();
            }
            const nodeEl = document.createElement("div");
            nodeEl.textContent = node.text;
            nodeEl.style.color = `#fff`;
            nodeEl.style.lineHeight = 20;
            nodeEl.className = "node-label";
            return new CSS2DObject(nodeEl);
          }
        })
        .onNodeClick(
          debounce((node, e) => {
            stopAnimation();

            showAllNode.current = false;
            console.log("onNodeClick");

            setCurTabIndex(
              sujectData.current.map((item) => item.name_zh).indexOf(node.group)
            );

            setCurrentClickNode({ node, pos: { x: e.offsetX, y: e.offsetY } });
            // isStop.current = true;
            forceUpdate({});
          }, 600)
        )
        .onBackgroundClick(() => {
          handleDivStop();
        })
        .onLinkClick(() => {
          handleDivStop();
        })
        .linkOpacity(1)
        .linkDirectionalParticleColor("rgb(255,0,0,1)")

        .nodeThreeObjectExtend(true)
        .cameraPosition({ z: 5000 })
        .pauseAnimation();

      const controls = myGraph.controls();
      const scene = myGraph.scene();
      const renderer = myGraph.renderer();
      renderer.sortObjects = true;

      setTimeoutRender();
      // wormholeEffect.start(scene);
      // resolution：表示泛光所覆盖的场景大小，是Vector2类型的向量
      // strength：表示泛光的强度，值越大明亮的区域越亮，较暗区域变亮的范围越广
      // radius：表示泛光散发的半径
      // threshold：表示产生泛光的光照强度阈值，如果照在物体上的光照强度大于该值就会产生泛光
      // const bloomPass = new UnrealBloomPass(
      //   new THREE.Vector2(1, 1),
      //   0.3,
      //   1,
      //   0.8,
      // );
      // myGraph.postProcessingComposer().addPass(bloomPass);

      var textureLoader = new THREE.TextureLoader();
      // 加载背景图片
      var texture = textureLoader.load("/dashboard/assets/images/fullbg.jpg");
      // 纹理对象Texture赋值给场景对象的背景属性.background
      scene.background = texture;
    }

    async function initData() {
      try {
        let res = await getScienceKnowledgeSubjects();
        if (res && Array.isArray(res)) {
          let resAllData1 = await getSubjectBylayer({
            layer: 1,
            first_subject_node_ids: res.slice(0).map((v) => v.node_id),
          });
          sujectData.current = res;

          if (resAllData1) {
            setReady(true);
            init();
            allData.current.part1 = resAllData1;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    function wheelCallBack() {
      handleDivStop();
    }

    initData();

    let callBack = function () {
      if (myGraph) {
        myGraph.width(window.innerWidth);
        myGraph.height(window.innerHeight);
      }
    };

    window.addEventListener("resize", callBack);
    window.addEventListener("wheel", wheelCallBack);

    return () => {
      clearTimeoutFn();
      destory();
      window.removeEventListener("resize", callBack);
      window.removeEventListener("wheel", wheelCallBack);
    };
  }, []);

  const reloadWindow = () => {
    if (loopNum.current > 150) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!myGraph) {
      return () => {};
    }
    reloadWindow();
    loopNum.current += 1;
    async function render() {
      if (nodes !== prevNodes || !nodes) {
        nodes = allData.current.part1;
        prevNodes = nodes;
        myGraph.graphData(nodes);
      }
      setAppear(sujectData.current[curTabIndex], nodes);
      updateSpriteText();
      updateHighlight();
    }

    render();
  }, [curTabIndex, ready, update]);

  const updateHighlight = () => {
    myGraph.linkColor(myGraph.linkColor()).linkWidth(myGraph.linkWidth());
  };

  const stopAnimation = () => {
    clearTimeoutFn();
    // myGraph.resumeAnimation();
    isStop.current = true;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      handleNext();
    }, 20 * 1000);
  };

  const startAnimation = () => {
    showAllNode.current = false;
    clearTimeoutFn();
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCurrentClickNode({});
    nodeDisplayInterval3 = requestAnimationFrame(startNodeDisplayInterval3);
    isStop.current = false;
  };

  const setTimeoutRender = () => {
    nodeDisplayInterval3 && cancelAnimationFrame(nodeDisplayInterval3);

    if (myGraph && currentAppear) {
      myGraph.resumeAnimation();

      timerText.current && clearTimeout(timerText.current);
      timerText.current = setTimeout(() => {
        updateSpriteText();
      }, 1600);
    }
  };

  const clearTimeoutFn = () => {
    nodeDisplayInterval3 && cancelAnimationFrame(nodeDisplayInterval3);
  };

  const getCameraPos = () => {
    let target = getCurrentTarget();
    let tarEarth = cor_to_ball(target[0], target[1], target[2]);
    let currentBall = cor_to_ball(curx, cury, curz);

    let phi = tarEarth[1] - currentBall[1];
    let theta = tarEarth[2] - currentBall[2];

    if (phi > 0 && phi > Math.PI) {
      phi = -(2 * Math.PI - phi);
    } else {
      if (phi < 0 && phi < -Math.PI) {
        phi = -(-2 * Math.PI - phi);
      }
    }

    let ballDirection = [phi / cameraStepNum, theta / cameraStepNum];

    let thisStepBall = [
      currentBall[0],
      currentBall[1] + ballDirection[0],
      currentBall[2] + ballDirection[1],
    ];
    return ball_to_cor(thisStepBall[0], thisStepBall[1], thisStepBall[2]);
  };

  const startNodeDisplayInterval3 = () => {
    let pos = getCameraPos();
    curx = pos[0];
    cury = pos[1];
    curz = pos[2];
    myGraph.cameraPosition({
      x: curx,
      y: cury,
      z: curz,
    });
    nodeDisplayInterval3 = requestAnimationFrame(startNodeDisplayInterval3);
  };

  const setAppear = (subject, nodes) => {
    if (!subject) {
      return false;
    }

    currentAppear = subject.name_zh;
    let targetNode = nodes.nodes.find((v) => v.name_zh === subject.name_zh);
    if (!targetNode) {
      console.log(subject);
    }
    let x = targetNode["fx"];
    let y = targetNode["fy"];
    let z = targetNode["fz"];

    nowTarget = [x, y, z];
  };

  const creatSpriteText = (threeNode, group) => {
    let node = threeNode.__data;
    let dis =
      (curx - node.fx) ** 2 + (cury - node.fy) ** 2 + (curz - node.fz) ** 2;
    let text = isZh ? node.name_zh : node.name;
    const sprite = new SpriteText(
      text.length > 16 ? text.substring(0, 16) + "..." : text
    );

    sprite._node = {
      group: node.group,
    };

    sprite.name = node.name_zh;

    sprite.material.transparent = true; // make sprite background transparent
    sprite.renderOrder = 90;
    sprite.material.depthFunc = THREE.AlwaysDepth;

    if (isActivity(node.group)) {
      sprite.textHeight = 4 * Math.log(dis);
    } else {
      sprite.textHeight = 0;
    }
    // sprite.scale.set(0.8, 0.8, 1);
    sprite.position.normalize();
    sprite.position.multiplyScalar(32);

    sprite.position.x = node.fx;
    sprite.position.y = node.fy;
    sprite.position.z = node.fz + 50;
    group.add(sprite);
  };

  const updateSpriteText = () => {
    const groupName = "text-group";
    // myGraph.pauseAnimation();
    const scene = myGraph.scene();
    // var textureNormal = globeTextureLoader.load(
    //   '/dashboard/assets/images/output.png',
    // );
    let currentNode = [];
    // 处理节点亮度
    scene.traverse((node) => {
      if (node.__graphObjType === "node") {
        if (isActivity(node.__data.group) || isActivity(node.__data.name_zh)) {
          if (showAllNode.current || isStop.current) {
            if (node.__data.layer === 0) {
              currentNode.unshift(node);
            }
          } else {
            if (
              isActivity(node.__data.name_zh) ||
              (showAllNode.current && node.__data.layer === 0)
            ) {
              currentNode.unshift(node);
            } else {
              currentNode.push(node);
            }
          }
        } else {
          if (node.__data.layer > 0) {
            node.material.opacity = 0.3;
          }
        }
      }
    });

    // 处理文本
    let preGroup = scene.getObjectByName(groupName);

    if (preGroup) {
      preGroup.traverse(function (obj) {
        if (obj.type === "Sprite") {
          obj.geometry.dispose();
          obj.material.dispose();
          !!obj.clear && obj.clear();
          obj = null;
        }
      });
      preGroup.clear && preGroup.clear();
      scene.remove(preGroup);
    }

    let currentGroup = new THREE.Group();
    currentGroup.name = groupName;
    let boxArr = [];
    let res = [];
    currentNode.map((node) => {
      node.material.opacity = 1;
      node.material.depthTest = true;
      node.material.depthWrite = false;
      if (!isStop.current || showAllNode.current) {
        calcTextPosition(node, boxArr, res);
      } else {
        res.push(node);
      }
    });

    if (timerLayer.current !== true) {
      timerLayer.current = true;
      clearTimeoutFn();

      startNodeDisplayInterval3();
    }

    res.map((node) => {
      creatSpriteText(node, currentGroup);
    });

    scene.add(currentGroup);

    // console.timeEnd();
  };

  const getCurrentTarget = () => {
    return nowTarget;
  };

  const handleDivStop = () => {
    stopAnimation();
    setCurrentClickNode({});
    showAllNode.current = true;
    forceUpdate({});
  };

  return (
    <div
      id="aaabb"
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "absolute",
        background: "url(fullbg.jpg) no-repeat ",
        backgroundSize: "cover",
      }}
    ></div>
  );
};

export default KgGraph;
