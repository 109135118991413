import { PortalLogin } from "@itic-sci/iticui";
import AuthApi from "./auth/index";
import { encrypt } from "./auth/crypto";
import { message } from "antd";
import React, { useState } from "react";
import styles from "./Login.less";
interface ILogin {
  setLoginVisiable: any;
  setUserInfo: any;
}

export default (props: ILogin) => {
  const { setLoginVisiable, setUserInfo } = props;
  const [isLogin, setIsLogin] = useState(false);
  const handleLogin = (data: any) => {
    AuthApi.login(data).then((res: any) => {
      if (res?.code === 0) {
        AuthApi.userInfo({}).then((info: any) => {
          if (info?.code === 0) {
            setIsLogin(true);
            setUserInfo(info.data);
            message.success("登录成功");
            window.localStorage.setItem("IS-USER-LOGIN", "1");
            setLoginVisiable(false);
          } else if (info?.message) {
            message.error(info?.message);
          }
        });
      } else if (res?.message) {
        message.error(res?.message);
      }
    });
  };
  return (
    <div className={styles.container}>
      <PortalLogin
        onFinish={(values: {
          username: string;
          password: string;
          agreement: boolean;
        }) => {
          handleLogin({
            register_name: encrypt(values.username),
            password: encrypt(values.password),
          });
        }}
        onClose={() => {
          setLoginVisiable(false);
        }}
        rules={{
          username: [
            {
              //eslint-disable-next-line
              pattern:
                /^((?:(?:\+|00)86)?1[3-9]\d{9})$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/,
              message: "请输入正确的邮箱或手机号",
            },
          ],
        }}
      />
    </div>
  );
};
