import React, { useState, useEffect } from 'react';
import { ArcText } from '@arctext/react';
import styles from './Control.less';
import Left from '../images/left.svg';
import Right from '../images/right.svg';

export default (props) => {
  const {
    handleNext,
    handlePre,
    toggleAnimate,
    isStop,
    curTabIndex,
    sujectData,
    showAllNode,
    forceUpdate,
  } = props;

  const [text, setText] = useState('暂停');
  return (
    <div className={styles.Control} key={Math.random()}>
      {/* <p>
        {sujectData.current?.length &&
          sujectData.current[
            (curTabIndex <= 0 ? sujectData.current.length : curTabIndex) - 1
          ].name_zh}
      </p> */}

      <ArcText
        upsideDown
        className={
          styles.leftInfo + ' ' + styles.commonInfo + ' ' + styles.font14
        }
        style={{}}
        radius={600}
        onClick={() => {
          setText('暂停');
          handlePre();
          // forceUpdate({});
        }}
        text={`${
          (sujectData.current?.length &&
            sujectData.current[
              (curTabIndex <= 0 ? sujectData.current.length : curTabIndex) - 1
            ].name_zh) ||
          '无数据'
        }`.padStart(11, ' ')}
        characterWidth={1.4}
      />

      <p
        onClick={() => {
          setText('暂停');
          handlePre();
          // forceUpdate({});
        }}
      >
        <img src={Left} className={styles.leftBtn} />
      </p>
      {/* <p
        style={{ margin: '0 20px' }}
        onClick={() => {
          // if (text !== '播放') {
          //   showAllNode.current = true;
          // } else {
          //   showAllNode.current = false;
          //   // handleNext();
          // }
          setText(text === '播放' ? '暂停' : '播放');
          toggleAnimate();
          forceUpdate({});
        }}
      >
        {isStop.current === false ? '暂停' : '播放'}
      </p> */}

      <ArcText
        upsideDown
        className={
          styles.commonInfo +
          ' ' +
          styles.middleInfo +
          ' ' +
          (`${
            (sujectData.current?.length &&
              sujectData.current[curTabIndex < 0 ? 0 : curTabIndex].name_zh) ||
            ''
          }`.length > 6
            ? styles.font16
            : styles.font18)
        }
        style={{}}
        onClick={() => {
          setText(text === '播放' ? '暂停' : '播放');
          toggleAnimate();
          if (!isStop.current) {
            handleNext();
          }

          forceUpdate({});
        }}
        text={
          isStop.current
            ? '重新播放'
            : `${
                (sujectData.current?.length &&
                  sujectData.current[curTabIndex < 0 ? 0 : curTabIndex]
                    .name_zh) ||
                ''
              }`
        }
        radius={600}
        characterWidth={1.8}
      />
      <p
        onClick={() => {
          setText('暂停');
          handleNext();
          forceUpdate({});
        }}
      >
        <img src={Right} className={styles.rightBtn} />
      </p>
      <ArcText
        upsideDown
        className={
          styles.rightInfo + ' ' + styles.commonInfo + ' ' + styles.font14
        }
        radius={600}
        style={{}}
        onClick={() => {
          setText('暂停');
          handleNext();
          forceUpdate({});
        }}
        text={`${
          (sujectData.current?.length &&
            sujectData.current[
              (curTabIndex >= sujectData.current.length - 1 ? 0 : curTabIndex) +
                1
            ].name_zh) ||
          '无数据'
        }`.padEnd(11, ' ')}
        characterWidth={1.4}
      />
    </div>
  );
};
