import CryptoJS from 'crypto-js';

const key = 'sdf8g4edxuweif80';
const iv = CryptoJS.enc.Utf8.parse('0000000000000000');

function aseEncrypt(msg: any, key: any) {
  key = PaddingLeft(key, 16); //保证key的长度为16byte,进行'0'补位
  key = CryptoJS.enc.Utf8.parse(key);
  // 加密结果返回的是CipherParams object类型
  // key 和 iv 使用同一个值
  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
  });
  // ciphertext是密文,toString()内传编码格式,比如Base64,这里用了16进制
  // 如果密文要放在 url的参数中 建议进行 base64-url-encoding 和 hex encoding, 不建议使用base64 encoding
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); //后端必须进行相反操作
}
// 确保key的长度,使用 0 字符来补位
// length 建议 16 24 32
function PaddingLeft(key: any, length: number) {
  let pkey = key.toString();
  let l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
}

export function encrypt(message: any) {
  let encrypt_msg = aseEncrypt(message, key);
  return encrypt_msg;
}

export function decrypt(message: any) {
  // let encryptedHexStr = CryptoJS.enc.Hex.parse(message);
  // let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  // let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CFB });
  // let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  // return decryptedStr.toString();

  var ciphertext = CryptoJS.enc.Base64.parse(message);

  // split IV and ciphertext
  var iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  // decryption
  var decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext } as any, key, {
    iv: iv,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
