import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { getSubjectDetail } from "../service";
import styles from "./NodeDetailModal.less";

export default (props) => {
  const { visiable, node = {}, pos } = props;

  const [detailData, setDetailData] = useState();

  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    if (node.node_id) {
      setDetailData({
        name: node.name,
        name_zh: node.name_zh,
      });

      const newModalStyle = {};
      newModalStyle.left = pos.x + 100;
      if (
        window.innerHeight - pos.y > 610 ||
        pos.y < window.innerHeight - pos.y
      ) {
        newModalStyle.top = pos.y - 100;
      } else {
        newModalStyle.bottom = window.innerHeight - pos.y;
        newModalStyle.top = "unset";
      }
      setModalStyle(newModalStyle);

      getSubjectDetail({
        node_id: node.node_id,
        person_size: 5,
        publication_size: 3,
      }).then((data) => {
        if (data && data.name) {
          setDetailData(data);
        }
      });
    } else {
      setModalStyle({});
      setDetailData({});
    }
  }, [node?.node_id]);

  if (!visiable || !modalStyle?.top) {
    return null;
  }
  return (
    <div>
      <div
        className={styles.subjectIcon}
        style={{ left: pos.x - 100, top: pos.y - 100 }}
        key={Math.random()}
      >
        {(detailData?.first_subject_info?.pic_url || detailData?.pic_url) && (
          <img
            src={detailData?.first_subject_info?.pic_url || detailData?.pic_url}
            alt=""
            className={styles.image}
          />
        )}
      </div>
      <div className={styles.modal} style={modalStyle} key={node.node_id}>
        <div className={styles.content}>
          <div className={styles.scrollWrapper}>
            <div className={styles.header}>
              <div className={styles.title}>{detailData?.name_zh}</div>
              <div className={styles.subTitle}>{detailData?.name}</div>
            </div>
            {Boolean(detailData?.abstract) && (
              <div className={styles.subjectDesc}>{detailData.abstract}</div>
            )}
            {Boolean(detailData?.first_subject_info) && (
              <div className={classNames(styles.section, styles.catagory)}>
                <div className={styles.label}>所属分类</div>
                <div
                  className={classNames(styles.sectionContent, styles.media)}
                >
                  {Boolean(detailData.first_subject_info.pic_url) && (
                    <div className={styles.imageBox}>
                      <img
                        src={detailData.first_subject_info.pic_url}
                        alt=""
                        className={styles.image}
                      />
                    </div>
                  )}
                  <div className={styles.meta}>
                    <div className={styles.title}>
                      {detailData.first_subject_info.name_zh}
                    </div>
                    <div className={styles.desc}>
                      {detailData.first_subject_info.name}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {detailData?.childrens?.length > 0 && (
              <div className={classNames(styles.section, styles.subjects)}>
                <div className={styles.label}>下级学科</div>
                <div className={classNames(styles.sectionContent, styles.list)}>
                  {detailData.childrens.slice(0, 7).map((item) => {
                    return (
                      <span key={item.node_id} className={styles.tag}>
                        {item.name_zh || item.name}
                      </span>
                    );
                  })}
                  {detailData?.childrens?.length > 7 && (
                    <span className={styles.tag}>...</span>
                  )}
                </div>
              </div>
            )}
            {detailData?.person_data?.hitList &&
              detailData?.person_data?.hitList.length > 0 && (
                <div
                  className={classNames(styles.section, styles.relatedPerson)}
                >
                  <div className={styles.label}>相关学者</div>
                  <div className={classNames(styles.sectionContent)}>
                    <div className={styles.list}>
                      {detailData.person_data.hitList.map((item) => {
                        return (
                          <div key={item.id} className={styles.avatarWrap}>
                            <img
                              className={styles.avatar}
                              src={
                                item.avatar ||
                                "//avatarcdn.aminer.cn/default/default.jpg!240"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.info}>
                      共收录{" "}
                      <span className={styles.total}>
                        {detailData.person_data.hitsTotal === 10000
                          ? "10000+"
                          : detailData.person_data.hitsTotal || "0"}
                      </span>{" "}
                      位相关学者
                    </div>
                  </div>
                </div>
              )}
            {detailData?.publication_data?.hitList &&
              detailData?.publication_data?.hitList.length > 0 && (
                <div
                  className={classNames(styles.section, styles.relatedPaper)}
                >
                  <div className={styles.label}>相关论文</div>
                  <div className={classNames(styles.sectionContent)}>
                    <div className={styles.list}>
                      {detailData.publication_data.hitList.map((item) => {
                        return (
                          <div key={item.id} className={styles.item}>
                            {item.title_zh || item.title}
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.info}>
                      共收录{" "}
                      <span className={styles.total}>
                        {detailData.publication_data.hitsTotal === 10000
                          ? "10000+"
                          : detailData.publication_data.hitsTotal || "0"}{" "}
                      </span>{" "}
                      相关论文
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
