import request from "./commons/request";
import getScienceKnowledgeSubjectsData from "./mock/science_knowledge_subjects";
import getSubjectDetailData from "./mock/science_knowlege_subject_detail";
import getSubjectBylayerData from "./mock/science_knowledge_graph_by_layer";

const mock = false;
const apifix = `https://brain.itic-sci.com`;
// 获取45个学科的json数据
export const getScienceKnowledgeSubjects = async () => {
  if (mock) {
    return getScienceKnowledgeSubjectsData.data;
  }
  try {
    let res = await request({
      url: `${apifix}/sci_brain/science_knowledge_subjects`,
      method: "get",
    });
    if (res?.code === 0) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// 获取节点的详情数据
export const getSubjectDetail = async (params = {}) => {
  if (mock) {
    return getSubjectDetailData;
  }
  try {
    let res = await request({
      url: `${apifix}/sci_brain/science_knowlege_subject_detail?node_id=${params.node_id}&person_size=${params.person_size}&publication_size=${params.publication_size}`,
      method: "get",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
  return {};
};

// 获取数据
export const getSubjectBylayer = async (params = {}) => {
  if (mock) {
    return getSubjectBylayerData;
  }
  try {
    let res = await request({
      url: `${apifix}/sci_brain/science_knowledge_graph_by_layer`,
      method: "post",
      data: params,
    });
    if (res?.code === 0) {
      return {
        nodes: res.data.nodes,
        links: res.data.edges,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
export function get(config, options) {
  return request({ ...config, method: "GET" }, options);
}

export function post(config, options) {
  return request({ ...config, method: "POST" }, options);
}
