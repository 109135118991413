import React, { useState, useEffect, useMemo, useRef } from "react";
import Graph from "./component/graph";
import Title from "./images/title.svg";
import TitleHead from "./images/titleHead.svg";
import LogoSrc from "./images/logo.svg";
import TimerBg from "./images/timerBg.svg";
import Timer from "./images/timer.svg";
import Part from "./component/Part";
import Control from "./component/Control";
import styles from "./App.less";
import FloatMenu from "./component/FloatMenu/index.jsx";
import { getScienceKnowledgeSubjects } from "./service";
import NodeDetailModal from "./component/NodeDetailModal";
import tj from "@itic-sci/teamjob";

// 轮播时长
const DEFAULTATUODELAY = 12000;

let _autoTimeout;
let runTimer = 0;

let curIndex = 0;
function Index() {
  const [lang, setLang] = useState("zh"); // 默认语言：中文 zh； 英文： en
  const graphRef = useRef();
  const isStop = useRef(false); // 是否暂停动画
  const sujectData = useRef({}); // 一级学科数据
  const showAllNode = useRef(false); // 是否进入交互模式，展示所有的节点
  const [_timer, setTimer] = useState(0);
  const [update, forceUpdate] = useState({});
  const [curTabIndex, setCurTabIndex] = useState(-1);
  const [curTab, setCurTab] = useState({}); // 当前轮播的tab
  const [currentClickNode, setCurrentClickNode] = useState({}); // 当前点击节点的信息
  const [ready, setReady] = useState(false);

  useEffect(() => {
    async function initData() {
      try {
        let res = await getScienceKnowledgeSubjects();
        sujectData.current = res;
        if (res) {
          changeActive(0);
          setCurTab(res[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    ready && initData();
    return () => {
      _autoTimeout && clearTimeout(_autoTimeout);
      // timer && clearTimeout(timer);
      _autoTimeout = null;
    };
  }, [lang, ready]);

  useEffect(() => {
    tj.track("click", {
      params: {
        // 上报参数
        event_id: "naturalscience_page",
        url: window.location.href,
      },
    });
  }, []);

  function setHtmlFontSize(designWidth = 1920) {
    // 设计稿宽度为1920，利用移动端rem的适配方案，动态修改html的值
    const clientWidth = Math.max(document.documentElement.clientWidth, 1366);

    // 选取的是 1920 ， * 100是为了防止浏览器默认行为，如果小于12px默认会处理为12px进行计算。在css中一定要除以100.
    var fontSize = (clientWidth / designWidth) * 1; // 计算完毕后，在设计稿上量出的尺寸直接写入，只是把px换成rem即可，其他内部需要动态设置为px的可以乘这个基数（size * fontSize / 100 + 'px'）
    var elHtml = document.getElementsByTagName("html")[0];

    // elHtml.style.fontSize = (fontSize > 80 ? 80 : fontSize < 50 ? 50 : fontSize) + 'px'; // 规定上限在80px，下限在50px，上下限值根据需求进行调整，也可以不进行调整。
    elHtml.style.fontSize = fontSize + "px";
  }

  //数据大屏自适应函数
  const handleScreenAuto = () => {
    if (!ready) return false;
    const designDraftWidth = 1920; //设计稿的宽度
    const designDraftHeight = 960; //设计稿的高度
    //根据屏幕的变化适配的比例
    const scale =
      document.documentElement.clientWidth /
        document.documentElement.clientHeight <
      designDraftWidth / designDraftHeight
        ? document.documentElement.clientWidth / designDraftWidth
        : document.documentElement.clientHeight / designDraftHeight;
    //缩放比例
    document.querySelector(
      "#titlePart"
    ).style.transform = `scale(${scale}) translate(-50%)`;

    setHtmlFontSize();
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    //初始化自适应  ----在刚显示的时候就开始适配一次
    handleScreenAuto();
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    window.onresize = () => handleScreenAuto();
    //退出大屏后自适应消失   ---这是react的组件销毁生命周期，如果你是vue则写在deleted中。最好在退出大屏的时候接触自适应
    return () => (window.onresize = null);
  }, [ready]);

  useEffect(() => {
    if (isStop.current && Object.keys(currentClickNode).length === 0) {
      showAllNode.current = true;
    } else {
      showAllNode.current = false;
    }

    forceUpdate({});
  }, [isStop.current, JSON.stringify(currentClickNode)]);

  const changeActive = (idx) => {
    curIndex = idx;
    autoChangeTab(DEFAULTATUODELAY);
  };
  const autoChangeTab = (time) => {
    if (_autoTimeout) {
      clearTimeout(_autoTimeout);
    }

    if (!isStop.current) {
      runTimer += time;
      setTimer(runTimer);
      changeTab(curIndex);
      curIndex++;
    }

    _autoTimeout = setTimeout(() => {
      autoChangeTab(time);
    }, time);
  };

  const changeTab = (index, change) => {
    let cIndex = index;
    curIndex = index;
    if (index >= sujectData.current?.length) {
      cIndex = 0;
      curIndex = 0;
      curIndex = 0;
    }

    const curTab = sujectData.current[cIndex];
    // const preTab = data.find((item, idx) => idx === preIndex);
    // console.log("curTabcurTabcurTab", { curTab, preTab, cIndex, index, len: data.length });
    setCurTabIndex(cIndex);
    // setActive(curTab.key);
    setCurTab(curTab);
  };

  const handleNext = () => {
    clearTimeout(_autoTimeout);
    graphRef.current && graphRef.current.startAnimation();
    changeActive(
      (curTabIndex >= sujectData.current.length - 1 ? 0 : curTabIndex) + 1
    );
  };

  const handlePre = () => {
    clearTimeout(_autoTimeout);
    graphRef.current && graphRef.current.startAnimation();
    changeActive(
      (curTabIndex <= 0 ? sujectData.current.length : curTabIndex) - 1
    );
  };

  const RenderKgGraph = useMemo(() => {
    return (
      <div className={styles.graph}>
        <Graph
          curTabIndex={curTabIndex}
          setCurTabIndex={setCurTabIndex}
          isStop={isStop}
          showAllNode={showAllNode}
          update={update}
          ready={ready}
          setReady={setReady}
          changeActive={changeActive}
          forceUpdate={forceUpdate}
          handleNext={handleNext}
          lang={lang}
          refs={graphRef}
          setCurrentClickNode={setCurrentClickNode}
          sujectData={sujectData}
        />
      </div>
    );
  }, [curTabIndex, update]);

  const NodeDetailModalMemo = useMemo(() => {
    return (
      <NodeDetailModal
        visiable={Boolean(currentClickNode.node)}
        node={currentClickNode.node || {}}
        pos={currentClickNode.pos}
      />
    );
  }, [JSON.stringify(currentClickNode?.node)]);

  const toggleAnimate = () => {
    clearTimeout(_autoTimeout);

    if (isStop.current) {
      graphRef.current && graphRef.current.startAnimation();
    } else {
      graphRef.current && graphRef.current.stopAnimation();
    }
  };

  // const currentSize = () => {
  //   if (window.innerWidth <= 1366) {
  //     return '1366';
  //   } else if (window.innerWidth <= 1600) {
  //     return '1600';
  //   } else {
  //     return '1920';
  //   }
  // };

  return (
    <div className={styles.rotation}>
      <img
        src={LogoSrc}
        className={styles.logo}
        onClick={() => {
          window.open("https://itic-sci.com/", "_self");
        }}
      />

      {ready && (
        <div className={styles.titlePart} id="titlePart">
          <img src={Title} className={styles.titleImg} />
          <img
            src={TitleHead}
            className={styles.titleImg + " " + styles.titleHead}
          />
          {!isStop.current && (
            <div className={styles.timer} key={Math.random()}>
              <img src={Timer} className={styles.timerMain} />

              <img src={TimerBg} className={styles.layerBlock} />
            </div>
          )}
          <Control
            toggleAnimate={toggleAnimate}
            handleNext={handleNext}
            handlePre={handlePre}
            sujectData={sujectData}
            curTabIndex={curTabIndex}
            isStop={isStop}
            forceUpdate={forceUpdate}
            showAllNode={showAllNode}
          />
        </div>
      )}

      {!isStop.current && (
        <Part
          curTab={curTab}
          handleNext={handleNext}
          showAllNode={showAllNode}
          isStop={isStop}
        />
      )}
      <FloatMenu />

      {RenderKgGraph}

      {NodeDetailModalMemo}
    </div>
  );
}

export default Index;
