import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import tj from "@itic-sci/teamjob";
// import "./mock";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

tj.init({
  env: process.env.UMI_ENV !== "prd" ? "dev" : "prod",
  commonParams: {
    product: "自然科学知识图谱",
  },
});
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

setTimeout(() => {
  window.location.reload();
}, 1 * 60 * 60 * 1000);

// 1小时强制刷新一次

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
