import React, { useState, useEffect, useRef, useMemo } from "react";
import { getSubjectDetail } from "../service";
import styles from "./part.less";

function LeftPart(props) {
  const { curTab = {}, handleNext, isStop } = props;
  const [data, setData] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1900);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    async function getData() {
      setToggle(true);
      let res = await getSubjectDetail({
        node_id: curTab.node_id,
        publication_size: 5,
        person_size: 5,
      });
      if (res && res.name) {
        setData(res);
      }
      setToggle(false);
    }
    curTab.node_id && getData();
  }, [curTab.node_id]);

  useEffect(() => {
    if (isStop.current) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [isStop.current]);

  useEffect(() => {
    let callBack = function () {
      setIsSmallScreen(window.innerWidth < 1900);
    };

    window.addEventListener("resize", callBack);

    return () => {
      window.removeEventListener("resize", callBack);
    };
  }, []);

  // 展示的条数
  const maxListlength = useMemo(() => {
    return isSmallScreen ? 3 : 5;
  }, [isSmallScreen]);

  const fontNum = useMemo(() => {
    return isSmallScreen ? 120 : 260;
  }, [isSmallScreen]);

  return (
    <>
      {data && data.pic_url && (
        <>
          <div
            className={
              styles.container +
              " " +
              styles.leftContainer +
              " " +
              (toggle ? styles.hide : "")
            }
          >
            {/* <img className={styles.logo} src={data.pic_url} alt="加载失败" /> */}
            <div>
              <p className={styles.name_zh}>{data.name_zh}</p>
              <p className={styles.name + " " + styles["text-two-ellipsis"]}>
                {data.name}
              </p>

              <div className={styles.introduce}>
                <p className={styles.title}>学科简介</p>
                <p className={styles.desc}>
                  {data?.abstract?.length > fontNum
                    ? data.abstract.substring(0, fontNum) + "..."
                    : data.abstract}
                </p>
              </div>

              <div className={styles.direction}>
                <p className={styles.title}>热点方向</p>
                {data?.keyword_trend?.slice(0, 5)?.map((item) => (
                  <div className={styles.subjectItem} key={item.subject_zh}>
                    <span className={styles.subject}>{item.subject_zh}</span>
                    <span className={styles.outlayer}>
                      <span
                        className={styles.innerlayer}
                        style={{
                          width:
                            Math.min(
                              item.freq / (data.keyword_trend[0].freq * 1.2),
                              1
                            ) *
                              100 +
                            "%",
                        }}
                      ></span>
                    </span>
                  </div>
                ))}
              </div>

              <div className={styles.organization}>
                <p className={styles.title}>相关机构</p>
                {data.org_trend.slice(0, maxListlength).map((item) => (
                  <div className={styles.iconLayer} key={item.subject}>
                    <div className={styles.left + " " + styles.needBackground}>
                      <img
                        src={item.pic_url}
                        alt="加载失败"
                        onError={(e) =>
                          (e.target.src =
                            "//avatarcdn.aminer.cn/default/default.jpg!240")
                        }
                      />
                    </div>
                    <div className={styles.right}>
                      <p
                        className={
                          styles.textTop + " " + styles["text-ellipsis"]
                        }
                      >
                        {item.subject_zh}
                      </p>
                      <p
                        className={
                          styles.textBottom + " " + styles["text-ellipsis"]
                        }
                      >
                        {item.subject}
                      </p>
                    </div>
                  </div>

                  // <div className={styles.subjectItem} key={item.subject}>
                  //   <span className={styles.subject}>{item.subject}</span>
                  // </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={
              styles.rightContainer +
              " " +
              styles.container +
              " " +
              (toggle ? styles.hide : "")
            }
          >
            <div className={styles.subSubject}>
              <p className={styles.title}>下级学科</p>
              <div className={styles.tagsCt}>
                {data.childrens.slice(0, 16).map((item) => (
                  <span className={styles.label} key={item.node_id}>
                    {item.name_zh}
                  </span>
                ))}
              </div>
            </div>

            <div className={styles.scholar}>
              <p className={styles.title}>相关学者</p>
              {data.person_data.hitList &&
                data.person_data.hitList.slice(0, maxListlength).map((item) => (
                  <div className={styles.iconLayer} key={item.id}>
                    <div className={styles.left}>
                      <img
                        src={item.avatar}
                        alt="加载失败"
                        onError={(e) =>
                          (e.target.src =
                            "//avatarcdn.aminer.cn/default/default.jpg!240")
                        }
                      />
                    </div>
                    <div className={styles.right}>
                      <p
                        className={
                          styles.textTop + " " + styles["text-ellipsis"]
                        }
                        style={{
                          marginTop:
                            item.affiliationZh || item.affiliation ? 0 : 8,
                        }}
                      >
                        {item.name_zh || item.name}
                      </p>
                      <p
                        className={
                          styles.textBottom + " " + styles["text-ellipsis"]
                        }
                      >
                        {item.affiliationZh || item.affiliation}
                      </p>
                    </div>
                  </div>
                ))}
              <p className={styles.totalCt}>
                共收录{" "}
                <span className={styles.total}>
                  {data.person_data.hitsTotal === 10000
                    ? "10000+"
                    : data.person_data.hitsTotal}
                </span>{" "}
                位相关学者
              </p>
            </div>

            <div className={styles.paper}>
              <p className={styles.title}>相关论文</p>
              {data.publication_data &&
                data.publication_data.hitList
                  .slice(0, maxListlength)
                  .map((item, index) => (
                    <div key={item.id} className={styles.paper_ct}>
                      <p
                        className={
                          styles.title_zh + " " + styles["text-two-ellipsis"]
                        }
                      >
                        {item.language === "zh" ? item.title_zh : item.title}
                      </p>
                      <p
                        className={
                          styles.authors_zh + " " + styles["text-two-ellipsis"]
                        }
                      >
                        {item.authors
                          .map((v) =>
                            item.language === "zh" ? v.nameZh : v.name
                          )
                          .filter((v) => !!v)
                          .join("，")}
                      </p>
                    </div>
                  ))}
              <p className={styles.totalCt}>
                共收录{" "}
                <span className={styles.total}>
                  {data.publication_data.hitsTotal === 10000
                    ? "10000+"
                    : data.publication_data.hitsTotal}
                </span>{" "}
                相关论文
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LeftPart;
