import * as THREE from 'three';

export const COLOR = {
  农学: '#F77234',
  纺织科学技术: '#b9ffd7',
  电子与通信技术: '#ed8a6b',
  核科学技术: '#722ED1',
  生物学: '#e77862',
  药学: '#9164af',
  物理学: '#dc82c8',
  动力与电气工程: '#def46a',
  地球科学: '#e47862',
  基础医学: '#de6e6a',
  经济学: '#f3c86b',
  天文学: '#b2dedd',
  环境科学技术: '#599f76',
  化学: '#00B42A',
  中医学与中药学: '#6683c8',
  机械工程: '#84bfda',
  化学工程: '#3491FA',
  能源科学技术: '#ffc96b',
  心理学: '#e578e2',
  计算机科学技术: '#d794cf',

  统计学: '#9eff7f',
  矿山工程技术: '#ef87e8',
  军事医学与特种医学: '#ed836b',
  预防医学与公共卫生学: '#499f76',
  安全科学技术: '#de6f6a',
  管理学: '#de6e6b',
  土木建筑工程: '#ef97e8',
  水产学: '#668c87',
  林学: '#5871c0',
  力学: '#8164af',
  临床医学: '#84bfdb',
  '航空、航天科学技术': '#F5319D',
  食品科学技术: '#f3d79a',
  交通运输工程: '#9264af',
  水利工程: '#dc83c8',
  冶金工程技术: '#8df2fc',
  数学: '#f59876',
  '畜牧、兽医科学': '#8ff3b8',
  材料科学: '#8fe3b8',
  测绘科学技术: '#e4d8cb',
  自然科学相关工程与技术: '#5971c0',

  自然科学领域: '#00ffa7',
};

//直角坐标转球坐标
export const cor_to_ball = (x, y, z) => {
  let r = Math.sqrt(x ** 2 + y ** 2 + z ** 2);
  let phi = Math.atan2(y, x);
  let theta = Math.acos(z / r);
  return [r, phi, theta];
};

//球坐标转直角坐标
export const ball_to_cor = (r, p, t) => {
  let x = r * Math.sin(t) * Math.cos(p);
  let y = r * Math.sin(t) * Math.sin(p);
  let z = r * Math.cos(t);
  return [x, y, z];
};

// 通过包围盒，计算每一个文本节点是否需要展示
export const calcTextPosition = (node, boxArr, res) => {
  let { fx, fy, fz, layer } = node.__data;
  let offset = 400; // 默认间距
  let point = new THREE.Vector3(fx, fy, fz);

  let target = boxArr.find((v) => v.containsPoint(point));
  if (!target || layer === 0) {
    let box = new THREE.Box3(
      new THREE.Vector3(fx - offset, fy - offset, fz - offset),
      new THREE.Vector3(fx + offset, fy + offset, fz + offset),
    );
    boxArr.push(box);
    res.push(node);
  }
};
